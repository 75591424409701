module.exports = [{
      plugin: require('/Users/samuelrollin/Documents/WebProjects/Gestion-mieux-etre/Website3/Gestion mieux-etre/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#98a184","theme_color":"#98a184","display":"minimal-ui","icon":"src/images/gestion_icon.png"},
    },{
      plugin: require('/Users/samuelrollin/Documents/WebProjects/Gestion-mieux-etre/Website3/Gestion mieux-etre/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-156011925-1","head":true,"pageTransitionDelay":0,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"gestionmieux-etre.com"},
    },{
      plugin: require('/Users/samuelrollin/Documents/WebProjects/Gestion-mieux-etre/Website3/Gestion mieux-etre/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
